import React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkPrivilege, getToken, getUser } from "./Common";
// import { SITE_PAGES } from "components/Navigation";

// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
  let userObj = getUser();
  let userPrivileges = {};
  if (userObj) {
    userPrivileges = userObj.privileges;
  }

  // let selectedTerminal = localStorage.getItem(Constants.SITE_PREFIX + 'selected_terminal');

  return (
    <Route
      {...rest}
      render={(props) => {
        // let hasAccess = Object.keys(userPrivileges).some(key => userPrivileges[key].includes(rest.access));
        let hasAccess = checkPrivilege(userPrivileges, rest.access);;
        // let isValidModule = SITE_PAGES.some(page => page.privilege === rest.access);

        if (rest.redirectTo) {
          return <Redirect to={{ pathname: rest.redirectTo, state: { from: props.location, tabId: props.tabId } }} />;
        }
        if (getToken()) {
          if (rest.access) {
            if (hasAccess) {
              // localStorage.setItem(Constants.SITE_PREFIX + 'selected_terminal', terminal.toLocaleLowerCase() === 'employee' ? 'Employee' : 'HR');
              return <Component {...props} {...rest} />;
            }
            // else if (isValidModule) {
            //   return (
            //     <Redirect
            //       to={{ pathname: "/unauthorized", state: { from: props.location } }}
            //     />
            //   );
            // } 
            else {
              return (
                <Redirect
                  to={{ pathname: "/404", state: { from: props.location } }}
                />
              );
            }
          } else {
            return <Component {...props} {...rest} />;
          }
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        // return getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }}
    />
  );
}

export default PrivateRoute;
