import React, { Component, Suspense, lazy } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import "./styles/Global.scss";

import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";
import { GlobalProvider } from 'contexts/GlobalContext.js';
import { getToken } from "./utils/Common";
import Loading from "./views/Loading";
import Toast from "./components/Toast";

const Login = lazy(() => import("./views/Login"));
const Main = lazy(() => import("./Main"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authLoading: true,
    };
  }

  componentDidMount() {
    const token = getToken();
    if (!token) {
      return;
    }
    this.setState({ authLoading: false });
  }

  render() {
    if (this.state.authLoading && getToken()) {
      return <div className="content">Checking Authentication...</div>;
    }

    return (
      <div>
        <Toast />
        <Router>
          <Suspense fallback={<Loading />}>
            <Switch>
              <PublicRoute path="/login" component={Login} />
              <GlobalProvider>
                <PrivateRoute path="/" component={Main} />
              </GlobalProvider>
            </Switch>
          </Suspense>
        </Router>
      </div>
    );
  }
}

export default App;
