import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

const alertSubject = new Subject();
const defaultId = "default-alert";

export const alertService = {
  onAlert,
  success,
  error,
  alert,
  warning,
  info,
};

export const alertType = {
  success: "success",
  error: "error",
  info: "info",
  alert: "alert-msg",
  warning: "warning",
};

// enable subscribing to alerts observable
function onAlert(id = defaultId) {
  return alertSubject.asObservable().pipe(filter((x) => x && x.id === id));
}

// convenience methods
function success(message, options) {
  handleAlert({ ...options, type: alertType.success, message });
}

function error(message, options) {
  handleAlert({ ...options, type: alertType.error, message });
}

function warning(message, options) {
  handleAlert({ ...options, type: alertType.warning, message });
}

function info(message, options) {
  handleAlert({ ...options, type: alertType.info, message });
}

function alert(message, options) {
  handleAlert({ ...options, type: alertType.alert, message });
}

// core alert method
function handleAlert(alert) {
  alert.id = alert.id || defaultId;
  alertSubject.next(alert);
}
