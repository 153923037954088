import React from 'react';
import '../styles/Loading.scss';

const Loading = () => {
    return (
        <div id='thinkteam-loader-wrapper'>
            <div className="loader-wrapper">
                <div className="main-logo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="33.029" height="40" viewBox="0 0 33.029 40">
                        <path id="Path_502" data-name="Path 502" d="M481.469,22.227a16.248,16.248,0,0,0-11.681,5.456l-1.231-4.552h-6.222l-.026,39.089h8.666V40.866c0-6.311,3.669-10.86,8.659-10.86,4.623,0,7.118,3.3,7.118,9.393V62.22h8.586V37.483c.073-9.026-5.65-15.263-13.869-15.263Z" transform="translate(-462.309 -22.22)" fill="#fff" />
                    </svg>
                </div>
                <div className="repeat-logo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28.58" height="40" viewBox="0 0 28.58 40">
                        <path id="Path_490" data-name="Path 490" d="M481.469,22.227a15.476,15.476,0,0,0-12.475,6.458c-1.983,3.158-2.2,6.542-2.236,10.714V62.22h4.217V40.866c0-6.311,3.669-10.86,8.659-10.86,4.623,0,7.118,3.3,7.118,9.393V62.22h8.586V37.483c.073-9.026-5.65-15.263-13.869-15.263Z" transform="translate(-466.758 -22.22)" fill="#fff" />
                    </svg>
                </div>
                <div className="head-disc-1"></div>
                <div className="head-disc-2"></div>
                <div className="head-disc-3"></div>
            </div>
        </div>
    )
}

export default Loading