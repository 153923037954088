import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./styles.scss";
import { alertService } from "../../services/alertService";

function readingTime(text) {
  if (!text) return 3.5;
  const wpm = 200;
  const words = text.trim().split(/\s+/).length;
  const time = +((words / wpm) * 60).toFixed(2);
  return time < 3.5 ? 3.5 : time;
}

export default function Toast({ type, autoHide=true}) {
  const [alerts, setAlerts] = useState([]);

  // console.log(autoHide)
  useEffect(() => {
    let subscription = alertService.onAlert(type).subscribe((alert) => {
      if (!alert.message) return;
      setAlerts([...alerts, alert]);

      // if(autoHide===true){
        setTimeout(() => {
          removeAlert(alert);
        }, readingTime(alert.message) * 1000);
      // }
    });

    return () => {
      subscription.unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeAlert = (alert) => {
    setAlerts(alerts.filter((x) => x !== alert));
  };

  return (
    alerts && (
      <div>
        <AnimatePresence>
          {alerts.map((alert, index) => (
            <motion.div
              initial={{ opacity: 0, x: "100vh" }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: "100vh" }}
              transition={{ type: "linear" }}
              id="toast-wrapper"
              className={alert.type}
              key={index}
            >
              <div className="toast-icon"></div>
              <div>{alert.message}</div>

              {/* {autoHide===false && 
                <>
                <div className="toast-icon"></div>
                <div>{alert.message}</div>
                <div className="cancel-delete-button-wrapper">
                  <button type="button" class="cancelbtn">Cancel</button>
                  <button type="button" class="deletebtn">Delete</button>
                </div></>
                } */}
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    )
  );
}
