import { getUser } from "utils/Common";

export default function useUserData() {
  const user = getUser();
  const isManager = user?.privileges?.employee?.includes('MANAGER') || false;
  const isHR = user?.privileges?.hr?.includes('HR') || false;

  return {
    user,
    isManager,
    isHR,
  }
}