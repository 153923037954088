import { useEffect, Fragment, useRef, useCallback } from "react";
import io from "socket.io-client";
import { WEB_SOCKET_URL } from "components/Constants";
import { getToken } from "utils/Common";
import { alertService } from "services/alertService";
import { useGlobalContext } from "contexts/GlobalContext";

const socket = io(WEB_SOCKET_URL, {
  auth: (cb) => {
    cb({ token: getToken() });
  },
  transports: ["websocket"],
  autoConnect: false,
  reconnection: false,
  reconnectionAttempts: 50,
});

export function useSocket() {
  return socket;
}

function log(msg, data = '') {
  const date = new Date().toLocaleString();
  console.log(`[SOCKET] - ${date} - ${msg}`, data);
}

export function connectSocket() {
  log(`connecting... connected: ${socket.connected}`, socket);
  socket.connect();
}

export function disconnectSocket() {
  socket.disconnect();
}

export function SocketConnectionManager({ children }) {
  const { isAppInActive } = useGlobalContext();
  const timeoutIDRef = useRef(null);

  const tryReconnect = useCallback((e) => {
    if (isAppInActive) return;
    timeoutIDRef.current = setTimeout(() => {
      socket.io.open((err) => {
        log('tryReconnect', err);
        if (err) {
          !isAppInActive && tryReconnect();
        }
      });
    }, 2000);
  }, [isAppInActive]);

  useEffect(() => {
    // connectSocket();

    socket.on("connect", () => {
      log(`connected`);
    });

    socket.on("disconnect", (reason, details) => {
      log(`disconnected due to reason:"${reason}", low-level reason:"${details?.message}"`);

      if (
        !['transport close', 'ping timeout', 'io client disconnect', 'transport error'].includes(reason)
      ) {
        alertService.warning(`[SOCKET] disconnected due to ${reason}`);
      }

      // if (reason === "io server disconnect") {
      //   // the disconnection was initiated by the server, you need to reconnect manually
      //   console.log(`[SOCKET] disconnected due to ${reason}, Reconnecting...`);
      //   socket.connect();
      // }
      if (reason !== "io client disconnect") {
        tryReconnect();
      }
    });

    // socket.io.on("close", tryReconnect);

    socket.on("connect_error", (error) => {
      if (error.message === "" || error.message === "timeout") {
        log(`connect_error due to "${error.message || 'double authentication'}" error. reconnecting...`, error);
        disconnectSocket();
        connectSocket();
      } else {
        log(`connect_error due to "${error.message}" error`, error);
      }
    });

    socket.io.on("error", (error) => {
      log(`error`, error);
    });

    return () => {
      socket.disconnect();
      if (timeoutIDRef.current) clearTimeout(timeoutIDRef.current);
    }
  }, [tryReconnect]);

  return <Fragment>{children}</Fragment>;
};